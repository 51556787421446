import { OwnerDocument } from './types';

export const environmentRoot = process.env.NODE_ENV === 'development' ? '' : '/oportal';

export const coastlineLightGrayHex = '#F3F4F7';

export const statementDownloadLink = (document: OwnerDocument, ownerId: number, includeAttachments = true) => {
  let path: string;
  const searchParams = new URLSearchParams({ owner_id: ownerId.toString() });

  if (includeAttachments) {
    searchParams.append('document_name', document.documentName);
  }

  if (document.attachmentsSize > 0 && includeAttachments) {
    path = `${environmentRoot}/statements/${document.documentId}.zip`;
    searchParams.append('all', 'all');
  } else {
    path = `${environmentRoot}/statements/${document.documentId}.pdf`;
  }

  return `${path}?${searchParams.toString()}`;
};

export const getIconForExtension = (extension: string | undefined) => {
  if (!extension) return 'fa-file';

  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
  const textExtensions = ['txt'];
  const pdfExtensions = ['pdf'];
  const documentExtensions = ['doc', 'docx'];

  if (imageExtensions.includes(extension)) {
    return 'fa-file-image';
  } else if (textExtensions.includes(extension)) {
    return 'fa-file-lines';
  } else if (pdfExtensions.includes(extension)) {
    return 'fa-file-pdf';
  } else if (documentExtensions.includes(extension)) {
    return 'fa-file-word';
  }
  return 'fa-file';
};
