import { environmentRoot } from '../common/src/utils';

export const loadTokenExIframeConfig = async () => {
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  };

  const resp = await fetch(`${environmentRoot}/tokenex_iframe_config`, { headers });
  return resp.json();
};

export const until = (conditionFunction: any) => {
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else setTimeout((_) => poll(resolve), 200);
  };
  return new Promise(poll);
};
